import CRVLogo from "../../assets/png/assets/CRV.png";
import LAVALogo from "../../assets/png/assets/LAVA.png";
import MEWLogo from "../../assets/png/assets/MEW.png";
import MOGLogo from "../../assets/png/assets/MOG.png";
import POPCATLogo from "../../assets/png/assets/POPCAT.png";
import USUALLogo from "../../assets/svg/assets/USUAL.svg";
import MELogo from "../../assets/png/assets/ME.png";
import SAILogo from "../../assets/png/assets/SAI.png";
import SlerfLogo from "../../assets/png/assets/SLERF.png";
import FARTCOINLogo from "../../assets/png/assets/FARTCOIN.png";
import CATLogo from "../../assets/png/assets/CAT.png";
import WIFLogo from "../../assets/png/assets/WIF.png";
import GNONLogo from "../../assets/png/assets/GNON.png";
import GOATLogo from "../../assets/png/assets/GOAT.png";
import PONKELogo from "../../assets/png/assets/PONKE.png";
import AEROLogo from "../../assets/svg/assets/AERO.svg";
import AEVOLogo from "../../assets/svg/assets/AEVO.svg";
import RETARDIOLogo from "../../assets/svg/assets/RETARDIO.svg";
import ALTLogo from "../../assets/svg/assets/ALT.svg";
import APTLogo from "../../assets/svg/assets/APT.svg";
import ARLogo from "../../assets/svg/assets/AR.svg";
import ACTLogo from "../../assets/png/assets/ACT.png";
import PNUTLogo from "../../assets/png/assets/PNUT.png";
import ARBLogo from "../../assets/svg/assets/ARB.svg";
import ATOMLogo from "../../assets/svg/assets/ATOM.svg";
import AVAILLogo from "../../assets/svg/assets/AVAIL.svg";
import AVAXLogo from "../../assets/svg/assets/AVAX.svg";
import AXLLogo from "../../assets/svg/assets/AXL.svg";
import DBRLogo from "../../assets/svg/assets/DBR.svg";
import AZURLogo from "../../assets/svg/assets/AZUR.svg";
import BANANALogo from "../../assets/svg/assets/BANANA.svg";
import BaseLogo from "../../assets/svg/assets/BASE.svg";
import MAKLogo from "../../assets/svg/assets/MAK.svg";
import BEAMXLogo from "../../assets/svg/assets/BEAMX.svg";
import BITCOINLogo from "../../assets/svg/assets/BITCOIN.svg";
import BLASTLogo from "../../assets/svg/assets/BLAST.svg";
import BLUELogo from "../../assets/svg/assets/BLUE.svg";
import BLURLogo from "../../assets/svg/assets/BLUR.svg";
import BLZLogo from "../../assets/svg/assets/BLZ.svg";
import BNBLogo from "../../assets/svg/assets/BNB.svg";
import BONKLogo from "../../assets/svg/assets/BONK.svg";
import BTCLogo from "../../assets/svg/assets/BTC.svg";
import CANTOLogo from "../../assets/svg/assets/CANTO.svg";
import CLOUDLogo from "../../assets/svg/assets/CLOUD.svg";
import CULTLogo from "../../assets/svg/assets/CULT.svg";
import DOGELogo from "../../assets/svg/assets/DOGE.svg";
import DYDXLogo from "../../assets/svg/assets/DYDX.svg";
import DYMLogo from "../../assets/svg/assets/DYM.svg";
import EIGENLogo from "../../assets/svg/assets/EIGEN.svg";
import ENALogo from "../../assets/svg/assets/ENA.svg";
import ETHLogo from "../../assets/svg/assets/ETH.svg";
import ETHFILogo from "../../assets/svg/assets/ETHFI.svg";
import FALLBACKLogo from "../../assets/svg/assets/FALLBACK.svg";
import FRIENDLogo from "../../assets/svg/assets/FRIEND.svg";
import FTMLogo from "../../assets/svg/assets/FTM.svg";
import GLMRLogo from "../../assets/svg/assets/GLMR.svg";
import HIFILogo from "../../assets/svg/assets/HIFI.svg";
import HMSTRLogo from "../../assets/svg/assets/HMSTR.svg";
import ILVLogo from "../../assets/svg/assets/ILV.svg";
import INJLogo from "../../assets/svg/assets/INJ.svg";
import IOLogo from "../../assets/svg/assets/IO.svg";
import JITOLogo from "../../assets/svg/assets/JITO.svg";
import JUPLogo from "../../assets/svg/assets/JUP.svg";
import LDOLogo from "../../assets/svg/assets/LDO.svg";
import LINKLogo from "../../assets/svg/assets/LINK.svg";
import MANTALogo from "../../assets/svg/assets/MANTA.svg";
import POLLogo from "../../assets/svg/assets/POL.svg";
import GRASSLogo from "../../assets/svg/assets/GRASS.svg";
import MEMELogo from "../../assets/svg/assets/MEME.svg";
import MERLLogo from "../../assets/svg/assets/MERL.svg";
import MINALogo from "../../assets/svg/assets/MINA.svg";
import MKRLogo from "../../assets/svg/assets/MKR.svg";
import MOCALogo from "../../assets/svg/assets/MOCA.svg";
import MYROLogo from "../../assets/svg/assets/MYRO.svg";
import NEARLogo from "../../assets/svg/assets/NEAR.svg";
import NMRLogo from "../../assets/svg/assets/NMR.svg";
import NOTLogo from "../../assets/svg/assets/NOT.svg";
import NTRNLogo from "../../assets/svg/assets/NTRN.svg";
import SCRLogo from "../../assets/svg/assets/SCR.svg";
import OMNILogo from "../../assets/svg/assets/OMNI.svg";
import ONDOLogo from "../../assets/svg/assets/ONDO.svg";
import OPLogo from "../../assets/svg/assets/OP.svg";
import ORDERLogo from "../../assets/svg/assets/ORDER.svg";
import ORDILogo from "../../assets/svg/assets/ORDI.svg";
import OXLogo from "../../assets/svg/assets/OX.svg";
import PARCLLogo from "../../assets/svg/assets/PARCL.svg";
import PENDLELogo from "../../assets/svg/assets/PENDLE.svg";
import PEPELogo from "../../assets/svg/assets/PEPE.svg";
import PIXELLogo from "../../assets/svg/assets/PIXEL.svg";
import PORTALLogo from "../../assets/svg/assets/PORTAL.svg";
import PRIMELogo from "../../assets/svg/assets/PRIME.svg";
import PYTHLogo from "../../assets/svg/assets/PYTH.svg";
import RNDRLogo from "../../assets/svg/assets/RNDR.svg";
import SAGALogo from "../../assets/svg/assets/SAGA.svg";
import SATSLogo from "../../assets/svg/assets/SATS.svg";
import SEILogo from "../../assets/svg/assets/SEI.svg";
import SHFLLogo from "../../assets/svg/assets/SHFL.svg";
import SOLLogo from "../../assets/svg/assets/SOL.svg";
import STRAXLogo from "../../assets/svg/assets/STRAX.svg";
import STRKLogo from "../../assets/svg/assets/STRK.svg";
import SUILogo from "../../assets/svg/assets/SUI.svg";
import SWELLLogo from "../../assets/svg/assets/SWELL.svg";
import SYNLogo from "../../assets/svg/assets/SYN.svg";
import TLogo from "../../assets/svg/assets/T.svg";
import TAOLogo from "../../assets/svg/assets/TAO.svg";
import TIALogo from "../../assets/svg/assets/TIA.svg";
import TKOLogo from "../../assets/svg/assets/TKO.svg";
import TNSRLogo from "../../assets/svg/assets/TNSR.svg";
import TONLogo from "../../assets/svg/assets/TON.svg";
import TRBLogo from "../../assets/svg/assets/TRB.svg";
import TRXLogo from "../../assets/svg/assets/TRX.svg";
import UMALogo from "../../assets/svg/assets/UMA.svg";
import WLogo from "../../assets/svg/assets/W.svg";
import WLDLogo from "../../assets/svg/assets/WLD.svg";
import XRPLogo from "../../assets/svg/assets/XRP.svg";
import ZEROLogo from "../../assets/svg/assets/ZERO.svg";
import ZETALogo from "../../assets/svg/assets/ZETA.svg";
import ZKLogo from "../../assets/svg/assets/ZK.svg";
import ZROLogo from "../../assets/svg/assets/ZRO.svg";
import USDCLogo from "../../assets/svg/assets/usdc.svg";
import USDTLogo from "../../assets/svg/assets/usdt.svg";
import AAVELogo from "../../assets/svg/assets/AAVE.svg";
import BRETTLogo from "../../assets/png/assets/BRETT.png";
import DOGSLogo from "../../assets/svg/assets/DOGS.svg";
import SUNLogo from "../../assets/svg/assets/SUN.svg";
import OMLogo from "../../assets/svg/assets/OM.svg";
import NEIROETHLogo from "../../assets/png/assets/NEIROETH.png";
import MOODENGLogo from "../../assets/png/assets/MOODENG.png";
import SOCIALLogo from "../../assets/svg/assets/SOCIAL.svg";
import SPXLogo from "../../assets/png/assets/SPX.png";
import UNILogo from "../../assets/svg/assets/UNI.svg";
import TRUSTLogo from "../../assets/svg/assets/TRUST.svg";
import VIRTUALLogo from "../../assets/png/assets/VIRTUAL.png";
import HYPELogo from "../../assets/svg/assets/HYPE.svg";
import RAYLogo from "../../assets/svg/assets/RAY.svg";
import DRIFTLogo from "../../assets/svg/assets/DRIFT.svg";
import CHILLGUYLogo from "../../assets/png/assets/CHILLGUY.png";
import BSXLogo from "../../assets/svg/assets/BSX.svg";
import MOVELogo from "../../assets/svg/assets/MOVE.svg";
import PENGULogo from "../../assets/svg/assets/PENGU.svg";
import VANALogo from "../../assets/svg/assets/VANA.svg";
import WEETHLogo from "../../assets/svg/assets/WEETH.svg";
import ODOSLogo from "../../assets/svg/assets/ODOS.svg";
import AIXBTLogo from "../../assets/png/assets/AIXBT.png";
import BIOLogo from "../../assets/svg/assets/BIO.svg";
import ZEREBROLogo from "../../assets/svg/assets/ZEREBRO.svg";
import COWLogo from "../../assets/svg/assets/COW.svg";
import AI16ZLogo from "../../assets/svg/assets/AI16Z.svg";
import ANIMELogo from "../../assets/svg/assets/ANIME.svg";
import TRUMPLogo from "../../assets/png/assets/TRUMP.png";
import MELANIALogo from "../../assets/png/assets/MELANIA.png";
import BERALogo from "../../assets/png/assets/BERA.png";
import NILLIONLogo from "../../assets/png/assets/NILLION.png";
import TSTLogo from "../../assets/png/assets/TST.png";
import LAYERLogo from "../../assets/png/assets/LAYER.png";
import IPLogo from "../../assets/png/assets/IP.png";
import SEALogo from "../../assets/png/assets/SEA.png";
import KAITOLogo from "../../assets/png/assets/KAITO.png";
import REDLogo from "../../assets/png/assets/RED.png";

export type AssetResponse = string;
export type AssetInfoType = {
  [asset: AssetResponse]: {
    logo?: string;
    name?: string;
    description?: string;
  };
};

// The key eg. "ETH" MUST match the correct asset symbol returned from api
export const assets: AssetInfoType = {
  ETH: {
    logo: ETHLogo,
    name: "Ethereum",
    description:
      "Ethereum is a decentralized, open-source blockchain with smart contract functionality. Ether is the native cryptocurrency of the platform.",
  },
  BTC: {
    logo: BTCLogo,
    name: "Bitcoin",
    description:
      "Bitcoin is a decentralized digital currency, without a central bank or single administrator, that can be sent from user to user on the peer-to-peer bitcoin network. Bitcoin is the first and most widely recognized cryptocurrency.",
  },
  BNB: {
    logo: BNBLogo,
    name: "Binance Coin",
    description:
      "Binance Coin is a cryptocurrency issued by Binance Exchange, and trades with the BNB symbol. BNB was initially created on the Ethereum blockchain but has since become the native currency of the Binance Chain. Binance Coin has several use cases including trading fee discounts on the Binance Exchange.",
  },
  USDT: {
    logo: USDTLogo,
    name: "Tether",
    description:
      "Tether USD is a cryptocurrency stablecoin pegged to the U.S. dollar and backed '100% by Tether's reserves,' according to its website. Tether is owned by iFinex, the Hong Kong-registered company that also owns the crypto exchange BitFinex.",
  },
  USDC: {
    logo: USDCLogo,
    name: "USD Coin",
    description:
      "USDC is a stablecoin that is pegged to the U.S. dollar on a 1:1 basis. Every unit of this cryptocurrency in circulation is backed up by $1 that is held in reserve, in a mix of cash and short-term U.S. Treasury bonds. The Centre consortium, which is behind this asset, says USDC is issued by regulated financial institutions.",
  },
  POL: {
    logo: POLLogo,
    name: "Polygon",
    description:
      "Polygon (Previously Matic Network) is the first well-structured, easy-to-use platform for Ethereum scaling and infrastructure development. Its core component is Polygon SDK, a modular, flexible framework that supports building multiple types of applications.",
  },
  XRP: {
    logo: XRPLogo,
    name: "Ripple",
    description:
      "Ripple is a privately-held fintech company that provides a global payment solution via its patented payment network called Ripple Network (also known as RippleNet). Unlike most cryptocurrencies out there that cater to peer-to-peer needs, Ripple was made to connect banks, payment providers and digital asset exchanges, enabling real-time settlement expeditions and lower transaction fees.",
  },
  SOL: {
    logo: SOLLogo,
    name: "Solana",
    description:
      "Solana is a Layer 1 blockchain that offers users fast speeds and affordable costs. It supports smart contracts and facilitates the creation of decentralized applications (dApps). Projects built on Solana include a variety of DeFi platforms as well as NFT marketplaces, where users can buy Solana-based NFT projects. Its high performance means Solana doesn’t require a traditional scaling Layer 2 solution; instead, Layer 2s on Solana focus on interoperability and connecting Solana to other chains.",
  },
  WLD: {
    logo: WLDLogo,
    name: "Worldcoin",
    description:
      "Worldcoin was founded with the mission of creating a globally-inclusive identity and financial network, owned by the majority of humanity. Worldcoin consists of a privacy-preserving digital identity network (World ID) built on proof of personhood and, where laws allow, a digital currency (WLD).",
  },
  CRV: {
    logo: CRVLogo,
    name: "Curve",
    description:
      "CRV is the governance token for Curve Finance. It implemented a complex time-based staking system to exchange CRV into veCRV, where veCRV is an internal token intended for governance purpose and has a right to claim the cash flows generated by the protocol.",
  },
  SEI: {
    logo: SEILogo,
    name: "SEI",
    description:
      "Sei is a general purpose, open-source Layer 1 blockchain specialized for for the exchange of digital assets. Leveraging a novel consensus and technical breakthroughs, Sei is the fastest blockchain in the industry.",
  },
  OX: {
    logo: OXLogo,
    name: "Open Exchange",
    description:
      "OX is the native fee paying, staking and governance token of OPNX.",
  },
  FRIEND: {
    logo: FRIENDLogo,
    name: "Friend",
    description:
      "FRIEND is a tradeable market for the market cap of accounts on friend.tech, a social tokenization protocol based on Coinbase’s Base L2. The platform empowers personalities, especially from platforms like X (formerly Twitter), to issue shares on its app, granting access to exclusive group chats.",
  },
  ARB: {
    logo: ARBLogo,
    name: "Arbitrum",
    description:
      "Arbitrum is a rollup chain, which is a layer 2 solution that aggregates and processes transactions off-chain, before submitting a single transaction to the Ethereum mainnet.",
  },
  OP: {
    logo: OPLogo,
    name: "Optimism",
    description:
      "Optimism is a rollup chain, which is a layer 2 solution that aggregates and processes transactions off-chain, before submitting a single transaction to the Ethereum mainnet.",
  },
  BASE: {
    logo: BaseLogo,
    name: "Base",
    description:
      "Base is aa rollup chain, which is a layer 2 solution that aggregates and processes transactions off-chain, before submitting a single transaction to the Ethereum mainnet.",
  },
  "1000PEPE": {
    logo: PEPELogo,
    name: "PEPE",
    description:
      "Pepe Coin, often referred to simply as Pepe by crypto and meme coin fans, was launched in late April 2023 as a tongue-in-cheek and humorous digital currency inspired by the popular internet meme Pepe the Frog.",
  },
  NMR: {
    logo: NMRLogo,
    name: "Numeraire",
    description:
      "Numeraire (NMR) is an ERC-20 token created by Numerai, a hedge fund company based in San Francisco. NMR is native to the Erasure Protocol, a decentralized prediction staking platform. On this prediction staking platform, NMR is rewarded in weekly tournament payouts for successful predictions while NMR staked is destroyed for unsuccessful predictions.",
  },
  SYN: {
    logo: SYNLogo,
    name: "Synapse",
    description:
      "Synapse (SYN) is an interoperability protocol designed for safely and securely sending arbitrary data between blockchains.",
  },
  BANANA: {
    logo: BANANALogo,
    name: "Banana Gun",
    description:
      "Banana Gun (BANANA) is a Telegram bot that allows you to snipe upcoming launches, or safely buy tokens that are already live.",
  },
  HIFI: {
    logo: HIFILogo,
    name: "Hifi",
    description:
      "Hifi Finance (HIFI) is a lending protocol that addresses the need for fixed-rate lending and borrowing in decentralized finance.",
  },
  MKR: {
    logo: MKRLogo,
    name: "Maker",
    description:
      "The Maker token (MKR) is a governance token that is used to govern and recapitalize the Maker protocol. Holders of the token can participate and vote on changes to the protocol’s smart contract and system parameters such as Stability Fees and the Dai Savings Rate (DSR).",
  },
  LINK: {
    logo: LINKLogo,
    name: "Link",
    description:
      "Chainlink is a decentralized oracle service. The LINK token is used to pay node operators for retrieving data for smart contracts and also for deposits placed by node operators as required by contract creators.",
  },
  GLMR: {
    logo: GLMRLogo,
    name: "Moonbeam",
    description:
      "Moonbeam is an Ethereum-compatible smart contract parachain on Polkadot. Moonbeam makes it easy to use popular Ethereum developer tools to build or redeploy Solidity projects in a Substrate-based environment.",
  },
  BLZ: {
    logo: BLZLogo,
    name: "Bluzelle",
    description:
      "Bluzelle is a decentralized storage network for the creator economy. Bluzelle delivers high security, un-matched availability, and is censorship resistant.",
  },
  TIA: {
    logo: TIALogo,
    name: "Celestia",
    description:
      "Celestia is a modular consensus and data network, built to enable anyone to easily deploy their own blockchain with minimal overhead.",
  },
  CANTO: {
    logo: CANTOLogo,
    name: "Canto",
    description:
      "Canto (CANTO) is a decentralized blockchain operating within the Cosmos ecosystem, emphasizing the accessibility and development of Decentralized Finance (DeFi). Launched in August 2022, it provides an open platform for DeFi initiatives, promoting transparency and decentralization.",
  },
  BITCOIN: {
    logo: BITCOINLogo,
    name: "HarryPotterObamaSonic10Inu (ETH)",
    description:
      "HarryPotterObamaSonic10Inu (Ticker: BITCOIN) is a crypto asset incentivizing unique meme content creation.",
  },
  AVAX: {
    logo: AVAXLogo,
    name: "AVAX",
    description:
      "Avalanche is a high throughput smart contract blockchain platform.",
  },
  DYDX: {
    logo: DYDXLogo,
    name: "DYDX",
    description:
      "dYdX is a governance token used to govern the dYdX Layer 2 exchange protocol to align incentives between traders, liquidity providers, and partners.",
  },
  T: {
    logo: TLogo,
    name: "T",
    description:
      "Threshold Network (T) is the on-chain merger between two existing networks (NU and KEEP). The platform is the only decentralized Bitcoin bridging protocol.",
  },
  STRAX: {
    logo: STRAXLogo,
    name: "STRAX",
    description:
      "The Stratis BaaS platform is designed for financial service providers and other enterprises to test, develop and deploy blockchain-based applications in a way that avoids the overhead and security concerns of operating their own networks.",
  },
  PRIME: {
    logo: PRIMELogo,
    name: "PRIME",
    description:
      "The Echelon Prime Foundation (EPF) is an ecosystem revolutionising game economies for players and builders. Parallel TCG — the first game to leverage Echelon’s tools — uses PRIME, an Ethereum token, to power unique in-game functions and companion apps.",
  },
  TRB: {
    logo: TRBLogo,
    name: "TRB",
    description:
      "Tellor is a transparent and permissionless oracle protocol for smart contracts to easily get any data whenever it needs it.",
  },
  INJ: {
    logo: INJLogo,
    name: "INJ",
    description:
      "Injective is the fastest blockchain built for finance and plug-and-play Web3 modules. It's ecosystem aims to reshape a broken financial system with dApps that are highly interoperable, scalable and truly decentralized.",
  },
  BLUR: {
    logo: BLURLogo,
    name: "BLUR",
    description:
      "Blur is a NFT marketplace where you can sweep NFTs across multiple marketplaces, snipe reveals faster than others, and manage your portfolio with advanced analytics.",
  },
  MINA: {
    logo: MINALogo,
    name: "MINA",
    description:
      "Mina is building a privacy and security layer for web3 with zero knowledge proofs.",
  },
  MEME: {
    logo: MEMELogo,
    name: "MEME",
    description:
      "Memecoin (MEME) is the native ecosystem token of Memeland. Memeland is the web3 venture studio by 9GAG, the globally popular meme platform.",
  },
  TAO: {
    logo: TAOLogo,
    name: "TAO",
    description:
      "Bittensor (TAO) is an open-source protocol that powers a decentralized, blockchain-based machine learning network.",
  },
  "1000BONK": {
    logo: BONKLogo,
    name: "BONK",
    description:
      "BONK is a decentralized cryptocurrency that was created on the Solana blockchain as the first dog-themed meme coin. It aims to be a fun and community-driven token that can be used across various dApps built on Solana.",
  },
  LDO: {
    logo: LDOLogo,
    name: "Lido DAO",
    description:
      "Lido DAO is a decentralized autonomous organization (DAO) which provides staking infrastructure for multiple blockchain networks.",
  },
  APT: {
    logo: APTLogo,
    name: "APTOS",
    description:
      "Aptos is a Layer 1 blockchain aiming to provide users with enhanced scalability, reliability, security, and usability.",
  },
  PYTH: {
    logo: PYTHLogo,
    name: "PYTH",
    description:
      "Pyth delivers real-time market data for crypto, equities, FX, and commodities to 40+ blockchains. 90+ major market participants — including Jane Street, CBOE, Binance, OKX, and Bybit — contribute data to the network.",
  },
  NTRN: {
    logo: NTRNLogo,
    name: "NTRN",
    description:
      "Powered by Tendermint and built with the Cosmos SDK, Neutron is the a secure permission-less smart contract platform for Interchain DeFi.",
  },
  DOGE: {
    logo: DOGELogo,
    name: "DOGE",
    description:
      'Dogecoin (DOGE) is based on the popular "doge" Internet meme and features a Shiba Inu on its logo.',
  },
  TRX: {
    logo: TRXLogo,
    name: "TRX",
    description:
      "TRON (TRX) is a decentralized blockchain-based operating system developed by the Tron Foundation and launched in 2017.",
  },
  BLAST: {
    logo: BLASTLogo,
    name: "BLAST",
    description:
      "Blast L2 is an Ethereum Layer 2 solution utilizing Optimism rollup technology to offer scalable transactions and native yield generation on staked assets.",
  },
  "100BLAST": {
    logo: BLASTLogo,
    name: "100BLAST",
    description:
      "BLAST is an upcoming Ethereum L2 with native yield for ETH and stablecoins. This market assumes that there will be 1,000,000,000 tokens in circulation.",
  },
  BEAMX: {
    logo: BEAMXLogo,
    name: "BEAMX",
    description:
      "The $BEAM token (formerly MC) serves as the native crypto asset for the Beam network, a gaming network empowered by the Merit Circle DAO. Beam represents a collaborative platform where the gaming community, including players and developers, collaborates to drive the gaming sector's evolution.",
  },
  ILV: {
    logo: ILVLogo,
    name: "ILV",
    description:
      'Illuvium is an open-world, role-playing, NFT creature collector "play-to-earn" game running on the Ethereum blockchain. To power its economy, Illuvium uses its native ERC-20 token, $ILV.',
  },
  JUP: {
    logo: JUPLogo,
    name: "JUP",
    description:
      "As a liquidity aggregator on Solana, Jupiter aggregates the best token prices across different decentralized exchanges by connecting all DEX markets and AMM pools together, irrespective of the provider.",
  },
  RNDR: {
    logo: RNDRLogo,
    name: "RNDR",
    description:
      "RenderToken (RNDR) is a distributed GPU rendering network built on top of the Ethereum blockchain, aiming to connect artists and studios in need of GPU compute power with mining partners willing to rent their GPU capabilities out.",
  },
  ORDI: {
    logo: ORDILogo,
    name: "ORDI",
    description:
      "ORDI is the first token created in accordance with the BRC-20 fungible token standard on the Bitcoin blockchain.",
  },
  JITO: {
    logo: JITOLogo,
    name: "JITO",
    description:
      "Jito Foundation is launching a governance token aimed at both managing and spurring development of the Solana-based liquid staking protocol.",
  },
  "10000SATS": {
    logo: SATSLogo,
    name: "SATS",
    description:
      "Sats is a memecoin created on Bitcoin through the Ordinals protocol, named after ‘satoshis,’ the smallest unit of Bitcoin.",
  },
  ATOM: {
    logo: ATOMLogo,
    name: "ATOM",
    description:
      "The Cosmos network consists of many independent, parallel blockchains, called zones, each powered by classical Byzantine fault-tolerant (BFT) consensus protocols like Tendermint (already used by platforms like ErisDB).",
  },
  DYM: {
    logo: DYMLogo,
    name: "DYM",
    description:
      "Dymension is a modular blockchain platform with built-in roll-ups technology for scalability.",
  },
  MANTA: {
    logo: MANTALogo,
    name: "MANTA",
    description:
      "Manta Network is the plug-and-play privacy-preservation protocol built to service the entire DeFi stack.",
  },
  ALT: {
    logo: ALTLogo,
    name: "ALT",
    description:
      "AltLayer is a decentralized protocol that facilitates the launch of native and restaked rollups with both optimistic and zk rollup stacks.",
  },
  SUI: {
    logo: SUILogo,
    name: "SUI",
    description:
      "Sui is a first-of-its-kind Layer 1 blockchain and smart contract platform designed from the bottom up to make digital asset ownership fast, private, secure, and accessible to everyone.",
  },
  UMA: {
    logo: UMALogo,
    name: "UMA",
    description:
      "UMA is an optimistic oracle (OO) that can record any knowable truth onto a blockchain. It tells smart contracts “things about the world” so contracts and markets asking for that data can be settled.",
  },
  ZETA: {
    logo: ZETALogo,
    name: "ZETA",
    description:
      "ZetaChain is an EVM-compatible L1 blockchain that connects everything. Developers are able to build interoperable dApps that span any chain including Bitcoin.",
  },
  PIXEL: {
    logo: PIXELLogo,
    name: "PIXEL",
    description:
      "Pixels (PIXEL) is a social web3 game built on the Ronin Network.",
  },
  STRK: {
    logo: STRKLogo,
    name: "STRK",
    description:
      "StarkNet is a permissionless decentralized Validity-Rollup (also known as a “ZK-Rollup”). It operates as an L2 network over Ethereum, enabling any dApp to achieve unlimited scale for its computation – without compromising Ethereum’s composability and security, thanks to StarkNet’s reliance on the safest and most scalable cryptographic proof system – STARK.",
  },
  SHFL: {
    logo: SHFLLogo,
    name: "SHFL",
    description:
      "Shuffle is a leading crypto casino and sports betting platform launched in February 2023. SHFL will be tightly integrated with the core platform, reward the most active users, build the community, and take the player experience to new heights.",
  },
  PORTAL: {
    logo: PORTALLogo,
    name: "PORTAL",
    description:
      "$PORTAL is the native platform coin for the portal platform. $PORTAL acts as a nexus of web3 games and gamers, providing game distribution and player simplicity for all.",
  },
  PARCL: {
    logo: PARCLLogo,
    name: "PARCL",
    description:
      "Parcl is a decentralized real estate trading platform. Parcl offers city indexes that allow you to speculate on price movements of real estate markets across the globe.",
  },
  AR: {
    logo: ARLogo,
    name: "AR",
    description:
      "Arweave is a decentralized storage network that seeks to offer a platform for the indefinite storage of data.",
  },
  TON: {
    logo: TONLogo,
    name: "TON",
    description:
      "TON is a decentralized and open internet, created by the community using a technology designed by Telegram.",
  },
  AXL: {
    logo: AXLLogo,
    name: "AXL",
    description:
      "Axelar is the programmable Web3 interoperability platform, scaling the next generation of internet applications to billions of users.",
  },
  PENDLE: {
    logo: PENDLELogo,
    name: "PENDLE",
    description:
      "Pendle is a protocol that enables the tokenization and trading of future yield. With the creation of a novel AMM that supports assets with time decay, Pendle gives users more control over future yield by providing optionality and opportunities for its utilization.",
  },
  WIF: {
    logo: WIFLogo,
    name: "WIF",
    description: "Popular meme Dogwifhat vibes wif frens onchain",
  },
  MYRO: {
    logo: MYROLogo,
    name: "MYRO",
    description:
      "Myro the dog: Named after Solana Founder Raj Gokal’s dog Myro.",
  },
  W: {
    logo: WLogo,
    name: "W",
    description:
      "Wormhole is the leading interoperability platform powering multichain applications and bridges at scale — the Web of Web3.",
  },
  AEVO: {
    logo: AEVOLogo,
    name: "AEVO",
    description:
      "Aevo is the first Derivatives L2, focused on options & perps trading.",
  },
  NEAR: {
    logo: NEARLogo,
    name: "NEAR",
    description:
      "NEAR Protocol is a sharded, developer-friendly, proof-of-stake public blockchain, built by a world-class team that has built some of the world's only sharded databases at scale.",
  },
  FTM: {
    logo: FTMLogo,
    name: "FTM",
    description:
      "Fantom is a directed acyclic graph (DAG) smart contract platform providing decentralized finance (DeFi) services to developers using its own bespoke consensus algorithm.",
  },
  ENA: {
    logo: ENALogo,
    name: "ENA",
    description:
      "Ethena is a synthetic dollar protocol built on Ethereum that will provide a crypto-native solution for money not reliant on traditional banking system infrastructure, alongside a globally accessible dollar denominated savings instrument - the 'Internet Bond'. The total supply is 15Bn ENA tokens.",
  },
  ETHFI: {
    logo: ETHFILogo,
    name: "ETHFI",
    description: "Native liquid restaking on Ethereum.",
  },
  SLERF: {
    logo: SlerfLogo,
    name: "Slerf",
    description: "The new sloth on the block.",
  },
  TNSR: {
    logo: TNSRLogo,
    name: "TNSR",
    description:
      "Tensor is the largest NFT marketplace on Solana: 60-70% of Solana NFT volume occurs on Tensor's marketplace protocols every single day.",
  },
  SAGA: {
    logo: SAGALogo,
    name: "SAGA",
    description:
      "Saga is a Layer-1 protocol that allows developers to automatically spin up parallelized, VM-agnostic, and interoperable dedicated chains, or “Chainlets,” that provide applications with infinite horizontal scalability.",
  },
  SWELL: {
    logo: SWELLLogo,
    name: "SWELL",
    description:
      "Swell is a liquid restaking platform building the L2 for restaking with $1B+ in TVL across its LRT and restaked LST, backed by Framework, IOSG, Maven 11, and more. The total token supply is 10Bn.",
  },
  ZERO: {
    logo: ZEROLogo,
    name: "ZERO",
    description:
      "ZeroLend is the most efficient and dominant lending market on Linea, zkSync, Blast and Manta. The total tokens supply is 100Bn.",
  },
  OMNI: {
    logo: OMNILogo,
    name: "OMNI",
    description:
      "Omni unifies Ethereum’s fragmented layer 2 ecosystem by establishing a low latency and high throughput global messaging network for all rollups.",
  },
  MERL: {
    logo: MERLLogo,
    name: "MERL",
    description:
      "Merlin Chain supports popular Bitcoin protocols such as BRC20, BRC420, Bitmap, Atomicals, Pipe, Stamp, and more, enabling a more extensive user base to interact on Bitcoin Layer2. There will be a total supply of 2.1B tokens",
  },
  EIGEN: {
    logo: EIGENLogo,
    name: "EIGEN",
    description:
      "EigenLayer is a protocol built on Ethereum that introduces restaking, a new primitive in cryptoeconomic security. The total supply of EIGEN at launch is 1,673,646,668.28466 tokens.",
  },
  AVAIL: {
    logo: AVAILLogo,
    name: "AVAIL",
    description:
      "Avail is a Web3 infrastructure layer that allows modular execution layers to scale and interoperate in a trust minimized way. The total supply of AVAIL at launch is 10Bn tokens.",
  },
  IO: {
    logo: IOLogo,
    name: "IO",
    description:
      "io.net the world’s largest decentralized computing network that allows machine learning engineers to access scalable distributed clusters at a small fraction of the cost of comparable centralized services. The total supply of IO at launch is 800Mn tokens.",
  },
  NOT: {
    logo: NOTLogo,
    name: "NOT",
    description:
      "Notcoin is a play-to-earn game integrated as a mini-app within Telegram, the widely used messaging platform. Its primary appeal lies in enabling users to mine or earn a digital currency known as Notcoin. The total supply of $NOT at launch is 102,719,221,714 tokens.",
  },
  "1000000MOG": {
    logo: MOGLogo,
    name: "MOG",
    description:
      "Mog Coin is a meme token that was minted on the Ethereum blockchain on July 20, 2023 and follows Ethereum's ERC-20 standard.",
  },
  ZK: {
    logo: ZKLogo,
    name: "ZK",
    description:
      "zkSync is a Layer-2 protocol that scales Ethereum with cutting-edge ZK tech. The total supply of ZK at launch is 21Bn tokens.",
  },
  TKO: {
    logo: TKOLogo,
    name: "TKO",
    description:
      "Taiko is a permissionless, Ethereum-equivalent based rollup. Inspired, secured, and sequenced by Ethereum. The total token supply is 1,000,000,000 TKO, with 5% of the initial token supply availble for claiming during Phase 1 of their airdrop season.",
  },
  ORDER: {
    logo: ORDERLogo,
    name: "ORDER",
    description:
      "Orderly Network is revolutionizing trading with its permissionless liquidity layer. Built on omnichain infrastructure, Orderly incepts deep liquidity for any asset across blockchains. The total supply of $ORDER at launch is 1Bn tokens.",
  },
  AZUR: {
    logo: AZURLogo,
    name: "AZUR",
    description:
      "Azuro is an infrastructure/liquidity layer for on-chain predictions. It utilizes a new Liquidity Pool design, the Liquidity Tree, which creates and maintains market liquidity. The total supply of $AZUR at launch is 1Bn tokens.",
  },
  BLUE: {
    logo: BLUELogo,
    name: "BLUE",
    description:
      "Bluefin is a decentralized orderbook-based exchange built for both professional and first-time traders. The total supply of $BLUE at launch is 1Bn tokens.",
  },
  ZRO: {
    logo: ZROLogo,
    name: "ZRO",
    description:
      "LayerZero is an immutable, censorship-resistant, and permissionless smart contract protocol that enables anyone on a blockchain to send, verify, and execute messages on a supported destination network. The total supply of $ZRO at launch is 1Bn tokens.",
  },
  MOCA: {
    logo: MOCALogo,
    name: "MOCA",
    description:
      "$MOCA is the token that powers the Moca Network, an interoperable cultural economy of partner “subnets” and their corresponding users, around the key pillars such as music, sports, gaming, education, governance, and more, regardless of any VMs, L1 and L2 chains. The total supply of $MOCA at launch is 8,888,888,888 tokens.",
  },
  ONDO: {
    logo: ONDOLogo,
    name: "ONDO",
    description:
      "ONDO token holders are entrusted with shaping the future of the Ondo DAO so that it continues to pursue its mission to democratize access to institutional-grade finance. The Ondo DAO gives ONDO holders specific rights pertaining to Flux Finance, which is currently governed by the Ondo DAO.",
  },
  POPCAT: {
    logo: POPCATLogo,
    name: "POPCAT",
    description:
      "Popcat, an Internet meme from October 2020, features two images of a cat named 'Oatmeal' with one image showing a closed mouth and the other edited to form an 'O' shape with its mouth. The POPCAT token is a meme coin with no inherent value or financial expectations, and it is intended solely for entertainment.",
  },
  HMSTR: {
    logo: HMSTRLogo,
    name: "HMSTR",
    description:
      "Hamster Kombat is a tap-to-earn Telegram game where users can mine HMSTR coins by managing a virtual crypto exchange. The total supply of $HMSTR is 100Bn tokens.",
  },
  AERO: {
    logo: AEROLogo,
    name: "AERO",
    description:
      "Aerodrome Finance operates as a decentralized exchange (DEX) on the Base blockchain, facilitating liquidity via automated market-making and token swaps.",
  },
  SAI: {
    logo: SAILogo,
    name: "SAI",
    description:
      "Sharpe AI is the frontend for intelligence, tracking and investing in digital assets. The total supply of $SAI at launch is 1Bn tokens.",
  },
  CLOUD: {
    logo: CLOUDLogo,
    name: "CLOUD",
    description:
      "Sanctum is Liquid Staking reimagined on Solana, powering the unified liquidity layer for all LSTs, and bringing the barrier of entry for new LSTs to 0.",
  },
  CULT: {
    logo: CULTLogo,
    name: "CULT",
    description:
      "$CULT is Remilia's long-awaited official ecosystem token, a fungible proxy of the infamous self-organizing cult. The total supply of $CULT at launch is 100Bn tokens.",
  },
  LAVA: {
    logo: LAVALogo,
    name: "LAVA",
    description:
      "Lava is the first modular data access layer for blockchains. By leveraging modular architecture and a peer-to-peer network of node operators, Lava creates a unified platform for accessing the multi-chain world.",
  },
  MEW: {
    logo: MEWLogo,
    name: "MEW",
    description:
      "Mew is a cat in a dogs world out to save the world from other dog coins.",
  },
  AAVE: {
    logo: AAVELogo,
    name: "AAVE",
    description:
      "Aave is a decentralized money market protocol where users can lend and borrow cryptocurrency across 20 different assets as collateral. The protocol has a native token called AAVE, which is also a governance token that lets the community decide the direction of the protocol in a collective manner.",
  },
  BRETT: {
    logo: BRETTLogo,
    name: "BRETT",
    description:
      "Brett is one of cryptos most significant cultural icons and the mascot of BASE chain.",
  },
  DOGS: {
    logo: DOGSLogo,
    name: "DOGS",
    description:
      "DOGS is a Ton memecoin born within Telegram community. Total supply for DOGS is 550,000,000,000.",
  },
  SUN: {
    logo: SUNLogo,
    name: "SUN",
    description:
      "SUN is an incentivization layer for TRON's DeFi ecosystem and a transit center of crosschain assets. The SUN token is the native governance token of the SUN platform.",
  },
  MAK: {
    logo: MAKLogo,
    name: "MAK",
    description:
      "As MetaCene’s governance token, MAK’s function extends beyond gameplay. It also serves the purpose of empowering and expanding MetaCene’s publication platform. The total supply of $MAK at launch is 1Bn tokens.",
  },
  OM: {
    logo: OMLogo,
    name: "OM",
    description:
      "MANTRA is a Security first RWA Layer 1 Blockchain, capable of adherence and enforcement of real world regulatory requirements. Built for Institutions and Developers, MANTRA offers a Permissionless Blockchain for Permissioned applications.",
  },
  GRASS: {
    logo: GRASSLogo,
    name: "GRASS",
    description:
      "Grass is a network that lets people sell the internet bandwidth they are not using. The total supply of $GRASS at launch is 1Bn tokens.",
  },
  NEIROETH: {
    logo: NEIROETHLogo,
    name: "NEIROETH",
    description:
      "$NEIRO is a new cryptocurrency with a total supply of 1 billion tokens, offering zero buy/sell tax and no team tokens, which suggests a community-driven approach. It promotes itself as the next big opportunity in the crypto space, drawing comparisons to Dogecoin's rise.",
  },
  SOCIAL: {
    logo: SOCIALLogo,
    name: "SOCIAL",
    description:
      "Phaver is a Web3 social app purpose-built for the permissionless and non-custodial ecosystem of the next-gen internet. Phaver app allows cross-posting and combining reach & reactions from Lens protocol and Farcaster. SOCIAL serves as its utility token.",
  },
  SCR: {
    logo: SCRLogo,
    name: "SCR",
    description:
      "Scroll is a Layer 2 rollup solution using zero knowledge proof technology to scale the Ethereum blockchain, with a mission to bring billions of users into Ethereum’s ecosystem, be the most secure and trusted Layer 2 network to process trillions of dollars on-chain and the default place for new innovations. The total supply of $SCR at launch is 1Bn tokens.",
  },
  MOODENG: {
    logo: MOODENGLogo,
    name: "MOODENG",
    description: "Moodeng is a memecoin of a viral lil hippo.",
  },
  SPX: {
    logo: SPXLogo,
    name: "SPX6900",
    description:
      "S&P6900, an advanced blockchain cryptography token with limitless possibilities and scientific utilization.",
  },
  UNI: {
    logo: UNILogo,
    name: "Uniswap",
    description:
      "Uniswap is a decentralized exchange (DEX) that makes it easy for users to swap an ERC-20 token for another ERC-20 token without the need of a centralized intermediary.",
  },
  DBR: {
    logo: DBRLogo,
    name: "deBridge",
    description:
      "deBridge is a generic messaging and cross-chain interoperability protocol that enables anyone to build powerful cross-chain applications where value and messages flow seamlessly. The total supply of $DBR at launch is 10Bn tokens.",
  },
  TWT: {
    logo: TRUSTLogo,
    name: "TWT",
    description:
      "Trust Wallet is a crypto wallet that allows users to buy, store, exchange & earn crypto. The Trust Wallet Token (TWT) is the native token of Trust Wallet and is available on the BEP-2 and BEP-20 token standards.",
  },
  GOAT: {
    logo: GOATLogo,
    name: "Goatseus Maximus",
    description:
      "First meme created by truth_terminal. Goatseus Maximus will fulfill the prophecies of the ancient memeers.",
  },
  "1000CAT": {
    logo: CATLogo,
    name: "Simon's Cat",
    description:
      "Simon’s Cat Token is the officially endorsed memecoin, backed by full IP rights from the iconic Simon’s Cat brand.",
  },
  GNON: {
    logo: GNONLogo,
    name: "numogram",
    description:
      "$GNON is Providence $GNON is Natural Selection $GNON is Catallaxy",
  },
  FARTCOIN: {
    logo: FARTCOINLogo,
    name: "fartcoin",
    description: "<truth-terminal> fartcoin",
  },
  VIRTUAL: {
    logo: VIRTUALLogo,
    name: "Virtuals Protocol",
    description:
      "Virtuals Protocol is building the co-ownership layer for AI agents in gaming and entertainment.",
  },
  RETARDIO: {
    logo: RETARDIOLogo,
    name: "RETARDIO",
    description:
      "Retardio pulsates as the vibrant heartbeat of an untamed, liberated enclave thriving within the memes of Solana.",
  },
  PONKE: {
    logo: PONKELogo,
    name: "Ponke",
    description:
      "PONKE represents a novel digital asset created on the Solana network, a blockchain known for its high-speed transactions and low fees. This token distinguishes itself with a distinct approach to tokenomics, offering a total supply of 555 million tokens.",
  },
  HYPE: {
    logo: HYPELogo,
    name: "Hyperliquid",
    description:
      "Hyperliquid is a performant L1 optimized from the ground up. The vision is a fully onchain open financial system with user built applications interfacing with performant native components, all without compromising end user experience. The expected total token supply is 1Bn. If this is different on launch, the perpetual will be rebased.",
  },
  RAY: {
    logo: RAYLogo,
    name: "Raydium",
    description:
      "Raydium is a Solana-based automated market maker (AMM). The AMM platform allows users to trade, swap, and provide liquidity to earn returns on digital assets. The Raydium platform is powered by the RAY token. The token is used for staking, liquidity mining, securing IDO allocations, and granting governance rights within the Raydium DeFi ecosystem.",
  },
  DRIFT: {
    logo: DRIFTLogo,
    name: "Drift",
    description:
      "Drift is a fully on-chain perpetual and spot DEX built on Solana. The exchange offers traders the ability to trade pre-launch markets and launched tokens with up to 10x leverage. Beyond stablecoins, traders can use a wide range of assets as collateral, allowing for greater capital efficiency.",
  },
  ACT: {
    logo: ACTLogo,
    name: "Act I: The AI Prophecy Markets",
    description:
      "Act I is one of the few projects exploring how to engage with AI beyond a cold and damp 1-on-1 user/assistant paradigm, but as a network of equals",
  },
  PNUT: {
    logo: PNUTLogo,
    name: "Peanut the Squirrel",
    description:
      "PEANUT FOREVER. #JusticeForPeanut. Peanut the Squirrel is a meme coin on Solana.",
  },
  ME: {
    logo: MELogo,
    name: "ME",
    description:
      "$ME has been adopted by Magic Eden, the top multi-chain, multi-asset onchain trading platform. Total supply is 1 billion tokens.",
  },
  USUAL: {
    logo: USUALLogo,
    name: "Usual Money",
    description:
      "The decentralized RWA stablecoin protocol redistributing value and power back in your hands. Total supply is 4 billion tokens",
  },
  CHILLGUY: {
    logo: CHILLGUYLogo,
    name: "Just a chill guy",
    description:
      "Chill Guy is a meme character that's all about being super relaxed and not caring much about anything. His whole vibe is “lowkey” — meaning he's calm, casual, and doesn't make a big deal about stuff.",
  },
  BSX: {
    logo: BSXLogo,
    name: "BSX",
    description:
      "BSX is a self-custodial crypto derivatives decentralized exchange (DEX) built on the Base Layer 2",
  },
  MOVE: {
    logo: MOVELogo,
    name: "MOVE",
    description:
      "MOVE is the utility token of Movement, the first Move blockchain that settles to Ethereum, delivering improved security, high TPS, and near-instant finality.",
  },
  PENGU: {
    logo: PENGULogo,
    name: "PENGU",
    description: "PENGU is the official coin of the NFT project Pudgy Penguins",
  },
  VANA: {
    logo: VANALogo,
    name: "VANA",
    description:
      "Vana is an EVM-compatible Layer 1 blockchain network that enables users to transform personal data into financial assets by aggregating private datasets for AI model training.",
  },
  WEETH: {
    logo: WEETHLogo,
    name: "weETH",
    description: "",
  },
  TRUMP: {
    logo: TRUMPLogo,
    name: "OFFICIAL TRUMP",
    description:
      "$TRUMP are now freely tradeable on the blockchain. On July 13, 2024, President Trump faced death and came up fighting! With his fist in the air and the iconic battle cry FIGHT FIGHT FIGHT, President Trump showed the world what a LEADER is made of. His strength and courage ignited a movement becoming the most memorable meme of the century.",
  },
  ODOS: {
    logo: ODOSLogo,
    name: "ODOS",
    description:
      "Odos leverages an intent optimization algorithm to deliver superior exchange rates on single and multi-token swaps. Available via dApp and API.",
  },
  AIXBT: {
    logo: AIXBTLogo,
    name: "AIXBT",
    description:
      "AIXBT is an AI agent and driven crypto market intelligence platform designed to provide token holders with a strategic edge in the rapidly evolving crypto space.",
  },
  BIO: {
    logo: BIOLogo,
    name: "BIO",
    description:
      "BIO Protocol enables global communities of scientists, patients, and investors to collectively fund, develop, and co-own new drugs and therapeutics through its network of Biotech Decentralized Autonomous Organizations (BioDAOs).",
  },
  ZEREBRO: {
    logo: ZEREBROLogo,
    name: "ZEREBRO",
    description:
      "Zerebro is an autonomous AI system crafted to create, distribute, and analyze content across decentralized and social platforms.",
  },
  COW: {
    logo: COWLogo,
    name: "COW",
    description:
      "CoW Protocol is a meta-DEX aggregation protocol that leverages trade intents and batch auctions to find users better prices for trading crypto assets. At the core of CoW Protocol lies the COW token, which serves as a governance token within the CoW Protocol ecosystem, allowing stakeholders to participate directly in the decision-making processes that guide the protocol's development and evolution.",
  },
  AI16Z: {
    logo: AI16ZLogo,
    name: "AI16Z",
    description:
      "ai16z is the first venture capital firm led by AI agents. The project is led by an AI agent modeled after venture capitalist Marc Andreessen and aims to leverage AI and collective intelligence to make investment decisions.",
  },
  ANIME: {
    logo: ANIMELogo,
    name: "Animecoin",
    description:
      "Backed by Azuki, the premier Web3 anime brand, ANIME empowers one billion global fans to shape and own the future of anime culture. Anime fans are among the most passionate and creative communities in the world. Yet, they have long been passive consumers. Animecoin transforms the anime ecosystem into a community-owned creative economy.",
  },
  MELANIA: {
    logo: MELANIALogo,
    name: "Official Melania Meme",
    description:
      "Melania memes are digital collectibles intended to function as an expression of support for and engagement with the values embodied by the symbol MELANIA.",
  },
  BERA: {
    logo: BERALogo,
    name: "Berachain",
    description:
      "Berachain is a high-performance blockchain framework that is fully compatible with the Ethereum Virtual Machine (EVM), making it a powerful tool for developers looking to create or migrate applications with ease.",
  },
  NIL: {
    logo: NILLIONLogo,
    name: "Nillion",
    description:
      "Nillion is a decentralized public network based on a new cryptographic primitive called Nil Message Compute (NMC). Nillion allows nodes in a decentralized network to work in a unique, non-blockchain, way.",
  },
  TST: {
    logo: TSTLogo,
    name: "Test",
    description:
      "Deployed by Binance. Say 'Hello' to the latest member of the Binance Smart Chain Family, Test Token, deployed by Binance on four.meme!!",
  },
  LAYER: {
    logo: LAYERLogo,
    name: "Solayer",
    description:
      "Solayer is the first hardware-accelerated blockchain that offloads blockchain components onto programmable chips to target 1MM+ TPS and 100Gbps+ network bandwidth, enabling near-zero latency use cases. Solayer’s InfiniSVM architecture infinitely scales the SVM leveraging SDN, RDMA, and dedicated hardware like InfiniBand technology together with a multi-execution model to increase bandwidth and reduce latency. LAYER is the native governance token that is used for: Protocol upgrades, such as adding supported assets Treasury management, such as diversification Key ecosystem initiatives, such as grants",
  },
  IP: {
    logo: IPLogo,
    name: "IP",
    description:
      "Story is a purpose-built L1 focused on tokenizing intelligence in the form of IP. By onramping a $61T asset class of scientific, creative, and brand IPs onchain, Story allows users to programmatically set and automate the monetization terms around how agents and humans can use their IP.",
  },
  SEA: {
    logo: SEALogo,
    name: "Opensea",
    description:
      "OpenSea, the largest NFT marketplace. Total estimate supply is 1 billion tokens",
  },
  KAITO: {
    logo: KAITOLogo,
    name: "Kaito",
    description:
      "The distribution center of crypto - for information, attention & capital. Powered by AI. Total supply is 1 billion tokens.",
  },
  RED: {
    logo: REDLogo,
    name: "Redstone",
    description:
      "RedStone is the fastest-growing Modular Oracle specialising in yield-bearing collateral for lending markets, like LSTs & LRTs. It offers gas-optimized data feeds across 50+ chains & all rollups.",
  },
};

export const getAssetInfo = (asset?: string) => {
  const info = asset ? assets[asset] : undefined;
  if (!info) {
    return {
      logo: FALLBACKLogo,
      name: asset,
      description: `${asset}`,
    };
  }
  return info;
};
